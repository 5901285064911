import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import "../../styles/notice.css"

const notice = () => (
  <Layout>
    <SEO title="Notice" />
    <div className="mainContent">
      <div className="noticePage">
        <div className="content">
          <div className="noticeHeader">공고 / 공지</div>
          <div className="noticeBody">
            <div className="titleArea">
              <div className="title">개발 및 제작 용역 공모</div>
              <div className="date">2023-10-23</div>
            </div>
            <div className="textArea">
              <div className="contentsArea">
                <div className="textTitle">개발 및 제작 용역 공모</div>
                <div className="textDesc">
                  <p>
                    <b>24년도 통신사향 키즈폰 관련 서버 및 통합 패키징 납품 용역 공모 공고</b>
                  </p>
                  <br />
                  <p>
                    2024년도 출시 예정인 “통신사향 키즈폰의 선탑 앱 서버 개발 및 악세서리 구성품 통합 패키징 납품” 용역 개발에
                    대한 공모를 다음과 같이 공고하오니, 관련 기업의 적극적인 참여를 바랍니다.
                  </p>
                  <br />
                  <p>- 다음 -</p>
                  <br />
                  <p>□ 공고 개요</p>
                  <ul>
                    <li>공모 및 접수 기간: 2023. 10. 23.(월) ~ 10. 27.(금) 18:00</li>
                    <li>신청방법 : 이메일 신청 ㈜스테이지파이브(https://www.stagefive.com/)</li>
                    <ul>
                      <li>제안요청서(RFP) [별첨자료1]</li>
                      <li>신청 양식 : 제안요청서(RFP)에서 요구하는 내용으로 업체 자유 서식으로 기한 내 제출</li>
                      <li>신청 메일 : 송준희 매니저 (giselle.song@stagefive.com)</li>
                    </ul>
                    <li>선정평가 안내</li>
                    <ul>
                      <li>평가일정 : 서류 도착 ~ 2023. 10. 31.(화)</li>
                      <ul>
                        <li>
                          서면 평가 : 2023.10. 25(수)~10. 31(화) <br />
                          *필요 시 서면 보완 및 발표를 요청할 수 있습니다.
                        </li>
                        <li>선정 결과 공지 : 2023. 11. 1.(수)</li>
                        <li>용역 시작일 : 2023. 11. 2.(목)</li>
                      </ul>
                    </ul>
                  </ul>
                  <br />
                  <p>□ 첨부파일</p>
                  <ul>
                    <li>
                      <a
                        href="https://shop-cdn.stagefive.io/z-web/download/스테이지파이브_용역개발_공문.pdf"
                        target="_blank"
                      >
                        공문 1부
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://shop-cdn.stagefive.io/z-web/download/[별첨자료1] 스테이지파이브_제안요청서_RFP.pdf"
                        target="_blank"
                      >
                        [별첨자료 1] 제안요청서(RFP) 1부
                      </a>
                    </li>
                  </ul>
                  <br />
                  <br />
                  <p className="footer">
                    2023년 10월 23일
                    <br />
                    주식회사 스테이지파이브 대표이사 서 상 원<br />
                    서울특별시 송파구 올림픽로35 123, 6층(신천동, 향군타워)
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default notice
